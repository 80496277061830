<template>
  <b-card-code
    :title="filter"
  >
    <b-card-body>
      <b-row>
        <b-col cols="6">
          <div class="custom-search d-flex justify-content-start">
            <b-button
              v-if="accessPermissions('order.store')"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              :to="{ name: 'workshops-orders-create' }"
            >
              <feather-icon
                icon="PlusCircleIcon"
                class="mr-50"
              />
              <span class="align-middle">Nuevo</span>
            </b-button>
          </div>
        </b-col>
        <b-col cols="6">
          <div class="custom-search d-flex justify-content-end">
            <b-form-group>
              <b-form-input
                v-if="accessPermissions('order.index')"
                id="filterInput"
                v-model="filter"
                type="search"
                placeholder="Buscar..."
                class="d-inline-block"
              />
            </b-form-group>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-table
            v-if="accessPermissions('order.list.index')"
            striped
            hover
            responsive
            class="position-relative"
            :per-page="perPage"
            :current-page="currentPage"
            :fields="fields"
            :items="rows"
            :filter="filter"
            @filtered="onFiltered"
          >
            <template #cell(order_number)="data">
              <div class="text-wrap">
                00000000{{ data.item.order_number }}
              </div>
            </template>

            <template #cell(customer)="data">
              <div class="text-wrap">
                {{ data.item.customer }}
              </div>
            </template>

            <template #cell(date_init)="data">
              <div class="text-wrap">
                {{ data.item.date_init }}
              </div>
            </template>

            <template #cell(date_end)="data">
              <div class="text-wrap">
                {{ data.item.date_end }}
              </div>
            </template>

            <template #cell(created)="data">
              <div class="text-wrap">
                {{ data.item.created }}
              </div>
            </template>

            <template #cell(technical)="data">
              <div class="text-wrap">
                {{ data.item.technical }}
              </div>
            </template>

            <template #cell(status)="data">
              <b-badge :variant="data.item.statusColor">
                {{ data.item.statusName }}
              </b-badge>
            </template>

            <template #cell(action)="data">
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template
                  v-if="accessPermissions('order.update') || accessPermissions('order.destroy')"
                  v-slot:button-content
                >
                  <feather-icon
                    icon="SettingsIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item
                  @click="onHandlePrint(data.item.id)"
                >
                  <feather-icon
                    icon="PrinterIcon"
                    class="mr-50"
                  />
                  <span>Imprimir</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="accessPermissions('order.update')"
                  :to="{ name: 'workshops-orders-edit', params: { id: data.item.id } }"
                >
                  <feather-icon
                    icon="EyeIcon"
                    class="mr-50"
                  />
                  <span>Editar</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="accessPermissions('order.update')"
                  @click="onHandleDelete(data.item.id)"
                >
                  <feather-icon
                    icon="TrashIcon"
                    class="mr-50"
                  />
                  <span>Eliminar</span>
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <div class="custom-search d-flex justify-content-start">
            <b-form-group
              label="Por Página"
              label-cols="6"
              label-align="left"
              label-size="sm"
              label-for="sortBySelect"
              class="text-nowrap mb-md-0 mr-1"
            >
              <b-form-select
                id="perPageSelect"
                v-model="perPage"
                size="sm"
                inline
                :options="pageOptions"
              />
            </b-form-group>
          </div>
        </b-col>
        <b-col cols="6">
          <div class="custom-search d-flex justify-content-end">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card-code>
</template>

<script>
import {
  BCardBody,
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BPagination,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BTable,
  BBadge,
} from 'bootstrap-vue'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import OrdersService from './services/OrdersService'
import errorsServices from '@/libs/errorsServices'
import permissions from '@/libs/permissions'
import { getItem, setItem } from '@/libs/storage'
import moment from 'moment'
import router from '@/router'

export default {
  name: 'OrderPage',
  components: {
    BCardBody,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BPagination,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BTable,
    BBadge,
    BCardCode,
  },
  data() {
    return {
      perPage: 50,
      pageOptions: [10, 20, 50, 100],
      totalRows: 0,
      currentPage: 1,
      filter: null,
      fields: [
        {
          key: 'order_number', label: '#Orden',
        },
        {
          key: 'customer', label: 'Cliente',
        },
        {
          key: 'date_init', label: 'Fecha Ingreso',
        },
        {
          key: 'date_end', label: 'Fecha Entrega',
        },
        {
          key: 'created', label: 'Recibido por',
        },
        {
          key: 'technical', label: 'Técnico',
        },
        {
          key: 'status', label: 'Estado',
        },
        {
          key: 'action', label: 'Acción',
        },
      ],
      rows: [],
    }
  },
  watch: {
    filter(value) {
      setItem('search_workshops_orders', value)
    },
  },
  mounted() {
    this.filter = getItem('search_workshops_orders') === undefined ? '' : getItem('search_workshops_orders')
  },
  async created() {
    await this.onHandleList()
  },
  methods: {
    accessPermissions(permission) {
      return permissions(permission)
    },
    errorResp(error) {
      const err = errorsServices(error)

      this.swal(err, 'error')
    },
    swal(text, type) {
      this.$swal({
        title: text,
        icon: type,
        showCancelButton: false,
        showConfirmButton: true,
        confirmButtonText: 'Aceptar',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        showClass: {
          popup: 'animate__animated animate__bounceIn',
        },
        buttonsStyling: false,
      })
    },
    async onHandleRedirect(id) {
      if (this.accessPermissions('order.show')) {
        await router.push({ name: 'workshops-orders-edit', params: { id: id } })
      }
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    async onHandlePrint(id) {
      await this.$swal({
        title: '¿Está seguro que desea imprimir la orden?',
        icon: 'warning',
        showCancelButton: true,
        showConfirmButton: true,
        cancelButtonText: 'No',
        confirmButtonText: 'Si',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        showClass: {
          popup: 'animate__animated animate__bounceIn',
        },
        buttonsStyling: false,
      }).then(({ value }) => {
        if (value) {
          OrdersService.handlePrint(id).then((data) => {
            if (data.status === 200) {
              this.$swal({
                title: 'Descargue el documento',
                html: `<a href="${data.data}" download="print.pdf" target="_blank">Descargar</a>`,
                icon: 'success',
                showCancelButton: false,
                showConfirmButton: true,
                confirmButtonText: 'Aceptar',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                showClass: {
                  popup: 'animate__animated animate__bounceIn',
                },
                buttonsStyling: false,
              })
            }
          }).catch(error => this.errorResp(error))
        }
      })
    },
    async onHandleDelete(id) {
      if (this.accessPermissions('order.destroy')) {
        await this.$swal({
          title: '¿Está seguro que desea eliminar la orden?',
          icon: 'warning',
          showCancelButton: true,
          showConfirmButton: true,
          cancelButtonText: 'No',
          confirmButtonText: 'Si',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          showClass: {
            popup: 'animate__animated animate__bounceIn',
          },
          buttonsStyling: false,
        }).then(({ value }) => {
          if (value) {
            OrdersService.handleDelete(id).then(({ data }) => {
              if (data.data) {
                this.onHandleList()
              }
            }).catch(error => this.errorResp(error))
          }
        })
      }
    },
    async onHandleList() {
      if (this.accessPermissions('order.index')) {
        await OrdersService.handleList().then(({ data }) => {
          let arrayList = []

          if (data.data.length > 0) {
            const array = data.data

            array.map(({ customer, create, reviewed, date_init, date_end, status }, index) => {
              const dateInit = date_init.split(' ')
              const dateEnd = date_end.split(' ')
              array[index].date_init = moment(dateInit[0]).format('DD/MM/YYYY')
              array[index].date_end = moment(dateEnd[0]).format('DD/MM/YYYY')

              if(array[index]?.create && array[index]?.reviewed) {
                array[index].created = create?.profile?.full_name ?? ''
                array[index].technical = reviewed?.profile?.full_name ?? ''
              } else {
                array[index].created = ''
                array[index].technical = ''
              }
              array[index].customer = (customer === '' || customer === null || customer === undefined) ? '' : customer.full_name

              if (status === 1) {
                array[index].statusName = 'Recibido'
                array[index].statusColor = 'primary'
              }

              if (status === 2) {
                array[index].statusName = 'En Proceso'
                array[index].statusColor = 'info'
              }

              if (status === 3) {
                array[index].statusName = 'Pendiente'
                array[index].statusColor = 'danger'
              }

              if (status === 4) {
                array[index].statusName = 'Finalizado'
                array[index].statusColor = 'success'
              }
            })

            arrayList = array
          }

          this.rows = arrayList.length === 0 ? null : arrayList
          this.totalRows = arrayList.length
        }).catch(error => {
          console.log(error);
        })
      }
    },
  },
}
</script>
